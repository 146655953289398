import axios from "axios";
import React, { useContext, useState, useEffect } from "react";
import eastIcon from "../../assets/icon/easy-icon.svg";
import hardIcon from "../../assets/icon/hard-icon.svg";
import mediumIcon from "../../assets/icon/medium-icon.svg";
import CreateTestContext from "../../store/CreateTestContext";
import { Grid, TextField } from "@mui/material";
import "./index.css";
import Warning from "./Warning";
function SkillSetTable(props) {
  const { queType, easy, medium, hard } = props;

  const [randomEasyMarks, setRandomEasyMarks] = useState(0);
  const [randomMediumMarks, setRandomMediumMarks] = useState(0);
  const [randomHardMarks, setRandomHardMarks] = useState(0);

  const [fixedEasyMarks, setFixedEasyMarks] = useState(0);
  const [fixedMediumMarks, setFixedMediumMarks] = useState(0);
  const [fixedHardMarks, setFixedHardMarks] = useState(0);

  const onChangeRandomEasyQuestionCount = (e) => {
    const newValue = e.target.value.slice(0, 3);
    setRandomEasyMarks(newValue);
    // if (
    //   newValue === "" ||
    //   parseInt(newValue) < 0 ||
    //   /^0+$|^0*-0+$/.test(newValue) ||
    //   parseInt(newValue) > hard
    // ) {
    //   seteasyMarksError(true);
    // } else {
    //   seteasyMarksError(false);
    // }
  };

  const onChangeRandomMediumQuestionCount = (e) => {
    const newValue = e.target.value.slice(0, 3);
    setRandomMediumMarks(newValue);
    // if (
    //   newValue === "" ||
    //   parseInt(newValue) < 0 ||
    //   /^0+$|^0*-0+$/.test(newValue) ||
    //   parseInt(newValue) > hard
    // ) {
    //   setmediumMarksError(true);
    // } else {
    //   setmediumMarksError(false);
    // }
  };

  const onChangeRandomHardQuestionCount = (e) => {
    const newValue = e.target.value.slice(0, 3);
    setRandomHardMarks(newValue);
    // if (
    //   newValue === "" ||
    //   parseInt(newValue) < 0 ||
    //   /^0+$|^0*-0+$/.test(newValue) ||
    //   parseInt(newValue) > hard
    // ) {
    //   sethardMarksError(true);
    // } else {
    //   sethardMarksError(false);
    // }
  };

  const onChangeFixedEasyQuestionCount = (e) => {
    const newValue = e.target.value.slice(0, 3);
    setFixedEasyMarks(newValue);
    // if (
    //   newValue === "" ||
    //   parseInt(newValue) < 0 ||
    //   /^0+$|^0*-0+$/.test(newValue) ||
    //   parseInt(newValue) > hard
    // ) {
    //   seteasyMarksError(true);
    // } else {
    //   seteasyMarksError(false);
    // }
  };

  const onChangeFixedMediumQuestionCount = (e) => {
    const newValue = e.target.value.slice(0, 3);
    setFixedMediumMarks(newValue);
    // if (
    //   newValue === "" ||
    //   parseInt(newValue) < 0 ||
    //   /^0+$|^0*-0+$/.test(newValue) ||
    //   parseInt(newValue) > hard
    // ) {
    //   setmediumMarksError(true);
    // } else {
    //   setmediumMarksError(false);
    // }
  };

  const onChangeFixedHardQuestionCount = (e) => {
    const newValue = e.target.value.slice(0, 3);
    setFixedHardMarks(newValue);
    // if (
    //   newValue === "" ||
    //   parseInt(newValue) < 0 ||
    //   /^0+$|^0*-0+$/.test(newValue) ||
    //   parseInt(newValue) > hard
    // ) {
    //   sethardMarksError(true);
    // } else {
    //   sethardMarksError(false);
    // }
  };

  return (
    <>
      <Grid xs={12} md={10}>
        <div className="skill-table">
          <table className="skillset" cellSpacing="0px">
            <thead>
              <tr>
                <th>Difficulty</th>
                <th>Random Questions</th>
                <th>Fixed Questions</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ height: "42px" }}>
                  <img style={{ cursor: "pointer" }} src={eastIcon} alt="" />
                  Easy {queType == "from_library" ? `(${easy})` : 0}
                </td>
                <td style={{ height: "42px" }}>
                  <TextField
                    error={
                      easy <=
                        parseInt(randomEasyMarks ? randomEasyMarks : 0) +
                          parseInt(fixedEasyMarks ? fixedEasyMarks : 0) &&
                      (parseInt(randomEasyMarks ? randomEasyMarks : 0) >
                      parseInt(fixedEasyMarks ? fixedEasyMarks : 0)
                        ? true
                        : true)
                    }
                    id={`textField-1`}
                    type="text"
                    size="small"
                    value={randomEasyMarks}
                    onChange={onChangeRandomEasyQuestionCount}
                    style={{ width: "40px" }}
                    InputProps={{
                      inputProps: {
                        type: "number",
                        pattern: "[0-9]*",
                      },
                    }}
                  />
                </td>
                <td style={{ height: "42px" }}>
                  <TextField
                    error={
                      easy <=
                        parseInt(randomEasyMarks ? randomEasyMarks : 0) +
                          parseInt(fixedEasyMarks ? fixedEasyMarks : 0) &&
                      (parseInt(randomEasyMarks ? randomEasyMarks : 0) >
                      parseInt(fixedEasyMarks ? fixedEasyMarks : 0)
                        ? true
                        : true)
                    }
                    id={`textField-1`}
                    type="text"
                    size="small"
                    value={fixedEasyMarks}
                    onChange={onChangeFixedEasyQuestionCount}
                    style={{ width: "40px" }}
                    InputProps={{
                      inputProps: {
                        type: "number",
                        pattern: "[0-9]*",
                      },
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td style={{ height: "42px" }}>
                  <img
                    onClick={props.closeSkillSetModel}
                    style={{ cursor: "pointer" }}
                    src={mediumIcon}
                    alt=""
                  />
                  Medium {queType == "from_library" ? `(${medium})` : 0}
                </td>
                <td style={{ height: "42px" }}>
                  <TextField
                    error={
                      medium <=
                        parseInt(randomMediumMarks ? randomMediumMarks : 0) +
                          parseInt(fixedMediumMarks ? fixedMediumMarks : 0) &&
                      (parseInt(randomMediumMarks ? randomMediumMarks : 0) >
                      parseInt(fixedMediumMarks ? fixedMediumMarks : 0)
                        ? true
                        : true)
                    }
                    id={`textField-1`}
                    type="text"
                    size="small"
                    value={randomMediumMarks}
                    onChange={onChangeRandomMediumQuestionCount}
                    style={{ width: "40px" }}
                    InputProps={{
                      inputProps: {
                        type: "number",
                        pattern: "[0-9]*",
                      },
                    }}
                  />
                </td>
                <td style={{ height: "42px" }}>
                  <TextField
                    error={
                      medium <=
                        parseInt(randomMediumMarks ? randomMediumMarks : 0) +
                          parseInt(fixedMediumMarks ? fixedMediumMarks : 0) &&
                      (parseInt(randomMediumMarks ? randomMediumMarks : 0) >
                      parseInt(fixedMediumMarks ? fixedMediumMarks : 0)
                        ? true
                        : true)
                    }
                    id={`textField-1`}
                    type="text"
                    size="small"
                    value={fixedMediumMarks}
                    onChange={onChangeFixedMediumQuestionCount}
                    style={{ width: "40px" }}
                    InputProps={{
                      inputProps: {
                        type: "number",
                        pattern: "[0-9]*",
                      },
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td style={{ height: "42px" }}>
                  <img
                    onClick={props.closeSkillSetModel}
                    style={{ cursor: "pointer" }}
                    src={hardIcon}
                    alt=""
                  />
                  Hard {queType == "from_library" ? `(${hard})` : 0}
                </td>
                <td style={{ height: "42px" }}>
                  <TextField
                    error={
                      hard <=
                        parseInt(randomHardMarks ? randomHardMarks : 0) +
                          parseInt(fixedHardMarks ? fixedHardMarks : 0) &&
                      (parseInt(randomHardMarks ? randomHardMarks : 0) >
                      parseInt(fixedHardMarks ? fixedHardMarks : 0)
                        ? true
                        : true)
                    }
                    id={`textField-1`}
                    type="text"
                    size="small"
                    value={randomHardMarks}
                    onChange={onChangeRandomHardQuestionCount}
                    style={{ width: "40px" }}
                    InputProps={{
                      inputProps: {
                        type: "number",
                        pattern: "[0-9]*",
                      },
                    }}
                  />
                </td>
                <td style={{ height: "42px" }}>
                  <TextField
                    error={
                      hard <=
                        parseInt(randomHardMarks ? randomHardMarks : 0) +
                          parseInt(fixedHardMarks ? fixedHardMarks : 0) &&
                      (parseInt(randomHardMarks ? randomHardMarks : 0) >
                      parseInt(fixedHardMarks ? fixedHardMarks : 0)
                        ? true
                        : true)
                    }
                    id={`textField-1`}
                    type="text"
                    size="small"
                    value={fixedHardMarks}
                    onChange={onChangeFixedHardQuestionCount}
                    style={{ width: "40px" }}
                    InputProps={{
                      inputProps: {
                        type: "number",
                        pattern: "[0-9]*",
                      },
                    }}
                  />
                </td>
              </tr>
              <tr>
                <th>Total Questions ({easy + medium + hard})</th>
                <th>
                  {parseInt(randomEasyMarks ? randomEasyMarks : 0) +
                    parseInt(randomMediumMarks ? randomMediumMarks : 0) +
                    parseInt(randomHardMarks ? randomHardMarks : 0)}
                </th>
                <th>
                  {parseInt(fixedEasyMarks ? fixedEasyMarks : 0) +
                    parseInt(fixedMediumMarks ? fixedMediumMarks : 0) +
                    parseInt(fixedHardMarks ? fixedHardMarks : 0)}
                </th>
              </tr>
            </tbody>
          </table>
        </div>
      </Grid>
      {easy + medium + hard <
        parseInt(randomEasyMarks ? randomEasyMarks : 0) +
          parseInt(randomMediumMarks ? randomMediumMarks : 0) +
          parseInt(randomHardMarks ? randomHardMarks : 0) && (
        <Warning
          msg={
            "Random questions should be less than no. of available questions"
          }
        />
      )}
      {hard <= parseInt(randomHardMarks ? randomHardMarks : 0) && (
        <Warning msg={`No. of random hard questions should be < ${hard}`} />
      )}
      {0 > parseInt(randomHardMarks ? randomHardMarks : 0) && (
        <Warning msg={`Random hard questions should be greater than zero`} />
      )}
      {medium <= parseInt(randomMediumMarks ? randomMediumMarks : 0) && (
        <Warning msg={`No. of random medium questions should be < ${medium}`} />
      )}
      {0 > parseInt(randomMediumMarks ? randomMediumMarks : 0) && (
        <Warning msg={"Random medium questions should be greater than zero"} />
      )}
      {easy <= parseInt(randomEasyMarks ? randomEasyMarks : 0) && (
        <Warning msg={`No. of random easy questions should be < ${easy}`} />
      )}
      {0 > parseInt(randomEasyMarks ? randomEasyMarks : 0) && (
        <Warning msg={"Random easy questions should be greater than zero"} />
      )}
      {easy <=
        parseInt(randomEasyMarks ? randomEasyMarks : 0) +
          parseInt(fixedEasyMarks ? fixedEasyMarks : 0) &&
        (parseInt(randomEasyMarks ? randomEasyMarks : 0) >
        parseInt(fixedEasyMarks ? fixedEasyMarks : 0) ? (
          <Warning
            msg={`Random and Easy question's total should be < ${easy}`}
          />
        ) : (
          <Warning
            msg={`Random and Easy question's total should be < ${easy}`}
          />
        ))}
      {medium <=
        parseInt(randomMediumMarks ? randomMediumMarks : 0) +
          parseInt(fixedMediumMarks ? fixedMediumMarks : 0) &&
        (parseInt(randomMediumMarks ? randomMediumMarks : 0) >
        parseInt(fixedMediumMarks ? fixedMediumMarks : 0) ? (
          <Warning
            msg={`Random and Medium question's total should be < ${medium}`}
          />
        ) : (
          <Warning
            msg={`Random and Medium question's total should be < ${medium}`}
          />
        ))}
      {hard <=
        parseInt(randomHardMarks ? randomHardMarks : 0) +
          parseInt(fixedHardMarks ? fixedHardMarks : 0) &&
        (parseInt(randomHardMarks ? randomHardMarks : 0) >
        parseInt(fixedMediumMarks ? fixedMediumMarks : 0) ? (
          <Warning
            msg={`Random and Hard question's total should be < ${hard}`}
          />
        ) : (
          <Warning
            msg={`Random and Hard question's total should be < ${hard}`}
          />
        ))}
    </>
  );
}

export default SkillSetTable;
