import {
  Box,
  FormControlLabel,
  FormGroup,
  Grid,
  Modal,
  Typography,
  makeStyles,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import React, { useContext, useState } from "react";
import closeIcon from "../../assets/icon/close-icon.svg";
import info_black from "../../assets/icon/info-black.svg";
import line from "../../assets/icon/line.svg";
import CreateTestContext from "../../store/CreateTestContext";
import Buttons from "./Buttons";
import { IOSSwitch } from "./CustomeSwitch";
import LeftSideBar from "./LeftSideBar";
import LibraryQuetionSelection from "./LibraryQueSelection";
import SkillSetTable from "./SkillSetTable";
import SkillValidation from "./SkillValidation";
import Warning from "./Warning";
import LibraryTable from "./LibraryTable";
import TabelRandomAndTopicWise from "./TableRandonAndTopicWise";
import ManualAddTable from "./MaualAddTable";
import "./index.css";
import axios from "axios";
import { backend_url, getCookie } from "../../constant";
import { toast } from "react-toastify";
import { useEffect } from "react";
import jwtDecode from "jwt-decode";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  // boxShadow: 24,
  px: 4,
  pb: 0,
  overflowY: "auto", // Enable vertical scrolling
  maxHeight: "100vh",
  width: "20%",
};
const skills = [
  { label: "Javascript", value: 1 },
  { label: "Python", value: 2 },
];

const questionType = [
  { label: "MCQ", value: 1 },
  { label: "Programing", value: 2 },
];

function AddSkillSet(props) {
  const createTestContext = useContext(CreateTestContext);
  const [isLibrary, setLibrary] = React.useState(false);
  const [isManuallyAdd, setMaunal] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState("");
  const [randomQue, setRandomQue] = useState({
    name: "",
    value: false,
  });
  const [selectedSkill, setSkillSelected] = useState([]);
  const [skillsArray, setSkillsArray] = useState([]);
  const [selectedTopics, setSelectedTopics] = useState([]);
  const [hoveredTopic, setHoveredTopic] = useState(null);
  const [skillSearchText, setskillSearchText] = useState("");
  const [searchSkillData, setsearchSkillData] = useState([]);
  const [showSkillSetData, setshowSkillSetData] = useState([]);
  const [type, setType] = useState("");
  const [easyMarks, setEasyMarks] = useState(0);
  const [mediumMarks, setMediumMarks] = useState(0);
  const [hardMarks, setHardMarks] = useState(0);
  const [info, setInfo] = useState({
    skillId: "",
    skill: "",
    questionType: "MCQ",
    level: "",
    questions: "",
    score: "",
    random: false,
  });

  const [totaleasymcqquestion, settotaleasymcqquestion] = useState(0);
  const [totalmediumquestion, settotalmediumquestion] = useState(0);
  const [totalhardquestion, settotalhardquestion] = useState(0);
  const [selectedQuestion, setSelectedQuestion] = useState(questionType[0]);
  const [searchSkillId, setSearchSkillId] = useState("");
  const [combinedArray, setCombinedArray] = useState([]);
  const [topics, setTopic] = useState([]);

  // const topics = ['Javascript', 'OOPs Concepts', 'Array'];
  const toggleTopic = (topic) => {
    if (selectedTopics.includes(topic)) {
      setSelectedTopics(
        selectedTopics.filter((selected) => selected !== topic)
      );
    } else {
      setSelectedTopics([...selectedTopics, topic]);
    }
  };
  const handleSwitchChange = (name) => async (event) => {
    let obj = {
      name: event.target.checked ? name : "",
      value: event.target.checked,
    };
    setRandomQue(obj);
    if (name === "RandomTopicWise") {
      if (event.target.checked) {
        try {
          const token = getCookie("Xh7ERL0G");
          const res = await axios.get(
            `${backend_url}skill/search?page=1&limit=5&searchText=${skillSearchText}`,
            { headers: { token: token } }
          );
          let Array = [];
          res.data.data.map((skill) => {
            if (skill.skills == skillSearchText) {
              skill.topics.map((topic) => {
                if (topic.topicId !== null)
                  Array.push({
                    topic: topic.topicId.topic,
                    topicsId: topic.topicId.topicsId,
                    _id: topic._id,
                  });
              });
            }
          });
          setTopic(Array);
        } catch (error) {
          toast(`${error}`, {
            className: "toast-message",
          });
        }
      }
    }
  };
  const handleChangeA = (event) => {
    setLibrary(event.target.checked);
    onChangeQuestionType("EliteQA Library");
  };

  const handleChangeB = (event) => {
    setMaunal(event.target.checked);
    onChangeQuestionType("My Library");
  };
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const handleArrayOfSetSkillData = (data) => {
    console.log(data);
    setCombinedArray((prevCombinedArray) => [
      ...prevCombinedArray,
      { value: data.value, label: data.label },
    ]);
  };

  const clearValue = () => {
    setskillSearchText("");
    searchSkillData.length = 0;
    setSkillSelected([]);
    setshowSkillSetData([]);
    settotaleasymcqquestion(0);
    settotalmediumquestion(0);
    settotalhardquestion(0);
    setCombinedArray([]);
  };

  const handleQuestionChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedQuestion(selectedValue);
  };

  const searchSkill = async (e) => {
    // console.log("Combined array : " , combinedArray)
    setskillSearchText(e.target.value);
    try {
      const token = getCookie("Xh7ERL0G");
      const res = await axios.get(
        `${backend_url}skill/search?page=1&limit=5&searchText=${e.target.value}`,
        { headers: { token: token } }
      );
      console.log(res.data.data);
      let Array = [];
      let Array2 = [];
      res.data.data.map((data) => {
        Array.push({
          value: data._id,
          label: data.skills,
        });
      });
      setsearchSkillData(Array);
    } catch (error) {
      toast(`${error}`, {
        className: "toast-message",
      });
    }
  };

  const addSearchedSkill = async (event) => {
    if (
      !showSkillSetData.includes(event.label) &&
      showSkillSetData.length < 1 &&
      !createTestContext.addedSkills.some((data) =>
        data.skill === event?.label ? true : false
      )
    ) {
      setshowSkillSetData([...showSkillSetData, event?.label]);
      setInfo({ skill: event?.label, skillId: event?.value });
      setType("MCQ");
      //   onChangeQuestionType(value);
      setSearchSkillId(event?.value);
    } else {
      if (
        showSkillSetData.includes(event?.label) ||
        createTestContext.addedSkills.some((data) =>
          data.skill === event?.label ? true : false
        )
      ) {
        // onChangeQuestionType(value);
        toast("You have added already that skill", {
          className: "toast-message",
        });
      } else {
        setskillSearchText("");
        toast.error("Only one skill can select");
      }
    }
  };

  const onChangeQuestionType = async (type) => {
    const token = getCookie("Xh7ERL0G");
    const decode = jwtDecode(token);
    const res = await axios.post(
      `${backend_url}question/getAddQuestionLevelAsPerSkillWise`,
      {
        eliteQALibraryId: createTestContext.testCreationTypeDetails.includes(
          "EliteQA Library"
        )
          ? "632c16db596546cfa858136f"
          : "",
        createdBy: createTestContext.testCreationTypeDetails.includes(
          "My Library"
        )
          ? [`${decode.user_id}`]
          : undefined,
        skills: [searchSkillId],
        createdByInOr:
          createTestContext.testCreationTypeDetails.length > 1 &&
          createTestContext.testCreationTypeDetails.includes("My Library")
            ? true
            : false,
        // healthScore: 20,
        question: createTestContext.question.map((data) => data.questions._id),
      },
      { headers: { token: token } }
    );
    settotaleasymcqquestion(res.data.easyMCQQuestion);
    settotalmediumquestion(res.data.mediumMCQQuestion);
    settotalhardquestion(res.data.hardMCQQuestion);

    // settotaleasyProgrammingquestion(res.data.easyProgrammingQuestion)
    // settotalMediumProgrammingquestion(res.data.mediumProgrammingQuestion)
    // settotalHardProgrammingquestion(res.data.hardProgrammingQuestion)

    // for (let i = 1; i <= ((res.data.easyMCQQuestion > 10) ? 10 : res.data.easyMCQQuestion); i++) {
    //     seteasyMarksOptions((prev) => [...prev, i])
    // }

    // for (let i = 1; i <= ((res.data.mediumMCQQuestion > 10) ? 10 : res.data.mediumMCQQuestion); i++) {
    //     setmediumMarksOptions((prev) => [...prev, i])
    // }

    // for (let i = 1; i <= ((res.data.hardMCQQuestion > 10) ? 10 : res.data.hardMCQQuestion); i++) {
    //     sethardMarksOptions((prev) => [...prev, i])
    // }
  };

  useEffect(() => {
    if (props.questionData !== undefined) {
      setInfo({
        skill: props?.questionData?.skill,
        skillId: props?.questionData?.skillId,
      });
      const level = props?.questionData?.level.split(",");
      // getUpdateSkillNoOfquestionAvailable();
      setType(props?.questionData?.questionType);
      setshowSkillSetData((prev) => [...prev, props?.questionData?.skill]);
      setEasyMarks(level[0]?.replace(/\D/g, ""));
      setMediumMarks(level[1]?.replace(/\D/g, ""));
      setHardMarks(level[2]?.replace(/\D/g, ""));
      if (props?.questionData?.random) {
        const score = props?.questionData?.score.split(",");
        // seteasyScore(score[0]?.replace(/\D/g, ""));
        // setmediumScore(score[1]?.replace(/\D/g, ""));
        // sethardScore(score[2]?.replace(/\D/g, ""));
      }
    }
  }, [selectedValue, skillSearchText]);

  return (
    <div>
      <Modal open={props.addSkill} onClose={props.closeSkillSetModel}>
        <Box sx={{ ...style, width: "80%", border: "none" }}>
          <Grid container spacing={0}>
            <LeftSideBar
              selectedSkill={selectedSkill}
              combinedArray={combinedArray}
            />
            <Grid item xs={8} md={9.5}>
              <div className="modal-heading-label">
                <span className="add-manage">Add / Manage Skillset</span>
                <img
                  onClick={props.closeSkillSetModel}
                  style={{ cursor: "pointer" }}
                  src={closeIcon}
                  alt=""
                />
              </div>
              <img src={line} alt="" className="line-width" />
              <Grid container spacing={2}>
                <Grid item xs={4} md={3.5}>
                  <span className="label">Choose Skill</span>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    value={combinedArray.length > 1 ? "" : skillSearchText}
                    options={searchSkillData ? searchSkillData : []}
                    sx={{
                      width: "auto",
                      marginTop: "10px",
                      height: "47px",
                      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                        {
                          borderColor: "#00C49A",
                          borderWidth: "1.5px",
                        },
                      "& .MuiAutocomplete-hasPopupIcon.css-14ijsjj-MuiAutocomplete-root .MuiOutlinedInput-root":
                        {
                          paddingRight: "10px !important",
                        },
                    }}
                    onChange={(event, value) => {
                      if (value !== null && value !== undefined) {
                        setSkillSelected(value);
                        addSearchedSkill(value);
                        handleArrayOfSetSkillData(value);
                      }
                    }}
                    // onClick={(event)=>addSearchedSkill(event)}
                    renderInput={(params) => (
                      <TextField
                        value={skillSearchText ? skillSearchText : ""}
                        onChange={searchSkill}
                        {...params}
                        style={{ paddingRight: "9px" }}
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: (
                            <>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="15"
                                height="15"
                                viewBox="0 0 15 15"
                                fill="none"
                              >
                                <path
                                  d="M12.8688 8.38125L8.3875 12.8625C8.27141 12.9787 8.13355 13.0709 7.9818 13.1338C7.83005 13.1967 7.66739 13.2291 7.50313 13.2291C7.33886 13.2291 7.1762 13.1967 7.02445 13.1338C6.8727 13.0709 6.73484 12.9787 6.61875 12.8625L1.25 7.5V1.25H7.5L12.8688 6.61875C13.1016 6.85295 13.2322 7.16977 13.2322 7.5C13.2322 7.83023 13.1016 8.14705 12.8688 8.38125V8.38125Z"
                                  stroke="#333333"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M4.375 4.375H4.38125"
                                  stroke="#333333"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </>
                          ),
                          endAdornment: (
                            <>
                              {selectedSkill != "" ? (
                                <img
                                  onClick={clearValue}
                                  style={{ cursor: "pointer" }}
                                  src={closeIcon}
                                  alt=""
                                />
                              ) : (
                                <svg
                                  onClick={clearValue}
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="18"
                                  height="19"
                                  viewBox="0 0 18 19"
                                  fill="none"
                                >
                                  <path
                                    d="M8.25 15.1289C11.5637 15.1289 14.25 12.4426 14.25 9.12891C14.25 5.8152 11.5637 3.12891 8.25 3.12891C4.93629 3.12891 2.25 5.8152 2.25 9.12891C2.25 12.4426 4.93629 15.1289 8.25 15.1289Z"
                                    stroke="black"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M15.7508 16.6287L12.4883 13.3662"
                                    stroke="black"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              )}
                            </>
                          ),
                          style: {
                            borderColor: params.focused ? "green" : "initial",
                          },
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={4} md={3}>
                  <FormControl sx={{ minWidth: 220, height: "47px" }}>
                    <span className="label">Question Type</span>
                    <Select
                      labelId="select-skill-2-label"
                      id="select-skill-2"
                      // label="Choose Skill 2"
                      style={{ marginTop: "10px" }}
                      value={selectedQuestion}
                      onChange={handleQuestionChange}
                    >
                      {questionType.map((question, index) => (
                        <MenuItem key={index + 1} value={question}>
                          {question.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                {selectedValue !== "manually_added" && (
                  <Grid item xs={4} md={3} ml={3}>
                    <FormControl sx={{ minWidth: 150 }}>
                      <span className="label">Total Available Questions</span>
                      <div className="bordered-container">
                        <span className="centered-text">
                          {totaleasymcqquestion ||
                          totalmediumquestion ||
                          totalhardquestion
                            ? totaleasymcqquestion +
                              totalmediumquestion +
                              totalhardquestion
                            : 0}
                        </span>
                      </div>
                    </FormControl>
                  </Grid>
                )}
              </Grid>
              <SkillValidation
                selectedSkill={selectedSkill}
                combinedArray={combinedArray}
              />
              <LibraryQuetionSelection
                handleChangeA={handleChangeA}
                selectedValue={selectedValue}
                isLibrary={isLibrary}
                handleChange={handleChange}
                isManuallyAdd={isManuallyAdd}
                handleChangeB={handleChangeB}
              />
              <div>
                <FormGroup row style={{ marginTop: "7px" }}>
                  <FormControlLabel
                    control={
                      <IOSSwitch
                        sx={{ m: 1 }}
                        checked={
                          randomQue.value && randomQue.name === "OnlyRandom"
                        }
                        onChange={handleSwitchChange("OnlyRandom")}
                      />
                    }
                    label={
                      <Typography
                        variant="body2"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <span className="radio-label">Only Random </span>
                        <Tooltip title="Only random" arrow placement="top">
                          <img
                            style={{ cursor: "pointer", marginLeft: "5px" }}
                            src={info_black}
                            alt=""
                          />
                        </Tooltip>
                      </Typography>
                    }
                  />

                  <FormControlLabel
                    control={
                      <IOSSwitch
                        sx={{ m: 1 }}
                        checked={
                          randomQue.value &&
                          randomQue.name === "RandomTopicWise"
                        }
                        onChange={handleSwitchChange("RandomTopicWise")}
                      />
                    }
                    label={
                      <Typography
                        variant="body2"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <span className="radio-label">
                          Random & Topic wise{" "}
                        </span>
                        <Tooltip
                          title="Tooltip for Random & Topic Wise"
                          arrow
                          placement="top"
                        >
                          <img
                            style={{
                              cursor: "pointer",
                              marginLeft: "5px",
                            }}
                            src={info_black}
                            alt=""
                          />
                        </Tooltip>
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    control={
                      <IOSSwitch
                        sx={{ m: 1 }}
                        checked={
                          randomQue.value && randomQue.name === "FixedTopicWise"
                        }
                        onChange={handleSwitchChange("FixedTopicWise")}
                      />
                    }
                    label={
                      <Typography
                        variant="body2"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <span className="radio-label">Fixed & Topic wise </span>
                        <Tooltip
                          title="Tooltip for Fixed & Topic Wise"
                          arrow
                          placement="top"
                        >
                          <img
                            style={{
                              cursor: "pointer",
                              marginLeft: "5px",
                            }}
                            src={info_black}
                            alt=""
                          />
                        </Tooltip>
                      </Typography>
                    }
                  />
                </FormGroup>
              </div>
              {randomQue.name === "OnlyRandom" && randomQue.value && (
                <>
                  <Grid mt={3}>
                    <SkillSetTable
                      queType={selectedValue}
                      easy={totaleasymcqquestion}
                      medium={totalmediumquestion}
                      hard={totalhardquestion}
                    />
                  </Grid>
                </>
              )}

              {(randomQue.name === "RandomTopicWise" ||
                randomQue.name === "FixedTopicWise") &&
                randomQue.value && (
                  <>
                    <Grid mt={2}>
                      <span className="label">Choose Topics</span>
                      <Grid>
                        {topics.map((topic) => (
                          <div
                            key={topic._id}
                            className={`${
                              selectedTopics.includes(topic.topic)
                                ? "choose-topic selected"
                                : "choose-topic-diselect"
                            } ${hoveredTopic === topic.topic ? "hovered" : ""}`}
                            onClick={() => toggleTopic(topic.topic)}
                            onMouseEnter={() => setHoveredTopic(topic.topic)}
                            onMouseLeave={() => setHoveredTopic(null)}
                          >
                            <span
                              className={`${
                                selectedTopics.includes(topic.topic) &&
                                hoveredTopic != topic.topic
                                  ? "choose-topic-name"
                                  : hoveredTopic === topic.topic &&
                                    selectedTopics.includes(topic.topic)
                                  ? "topic-name-hover"
                                  : "diselect-topic-name"
                              }`}
                            >
                              {topic.topic}
                            </span>
                            {selectedTopics.includes(topic.topic) ? (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="21"
                                viewBox="0 0 20 21"
                                fill="none"
                              >
                                <circle
                                  cx="10"
                                  cy="10.5"
                                  r="10"
                                  fill={
                                    hoveredTopic === topic.topic
                                      ? "#F23E3B"
                                      : "#00C49A"
                                  }
                                />
                                {hoveredTopic === topic.topic ? (
                                  <>
                                    <path
                                      d="M6.5 6.5L13.5 13.5"
                                      stroke="white"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                    <path
                                      d="M6.5 13.5L13.5 6.5"
                                      stroke="white"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </>
                                ) : (
                                  <path
                                    d="M13.5 7.75L8.6875 12.5625L6.5 10.375"
                                    stroke="white"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                )}
                              </svg>
                            ) : (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="21"
                                viewBox="0 0 20 21"
                                fill="none"
                              >
                                <circle
                                  cx="10"
                                  cy="10.5"
                                  r="10"
                                  fill="#F0F7FB"
                                />
                                <path
                                  d="M13.5 7.75L8.6875 12.5625L6.5 10.375"
                                  stroke="#BDCCD3"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            )}
                          </div>
                        ))}
                      </Grid>
                    </Grid>
                    <Grid mt={2}>
                      <TabelRandomAndTopicWise
                        selectedTopics={selectedTopics}
                      />
                    </Grid>
                  </>
                )}
              {selectedValue === "from_library" &&
                randomQue.name != "RandomTopicWise" &&
                randomQue.name != "OnlyRandom" &&
                randomQue.name != "FixedTopicWise" && (
                  <>
                    <LibraryTable
                      queType={selectedValue}
                      easy={totaleasymcqquestion}
                      medium={totalmediumquestion}
                      hard={totalhardquestion}
                    />
                    {/* <Warning msg={"Total no. of reuired question should be less than the total no. of available questions."} /> */}
                  </>
                )}
              {selectedValue === "manually_added" &&
                randomQue.name != "RandomTopicWise" &&
                randomQue.name != "OnlyRandom" &&
                randomQue.name != "FixedTopicWise" && (
                  <>
                    <ManualAddTable />
                    <Warning
                      msg={
                        "Total no. of reuired question should be less than the total no. of available questions."
                      }
                    />
                  </>
                )}
              <Buttons eventHandle={props} />
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}

export default AddSkillSet;
