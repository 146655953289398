import React from "react";
import './index.css'

const CandidateStatusFilter = ({candidateFilter,onChangeCandidateFilter,closeFilterPopup}) => {
    return (
        <div className="candidate-status-filter-main-container" >
            <div className="header" >
                <span>Select Status Filter</span>

                <svg onClick={closeFilterPopup} style={{ cursor: 'pointer' }} width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_7242_3918)">
                        <path d="M0.835938 9.66634L5.00408 5.49967M5.00408 5.49967L9.17074 1.33301M5.00408 5.49967L0.835938 1.33301M5.00408 5.49967L9.17074 9.66634" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
                    </g>
                    <defs>
                        <clipPath id="clip0_7242_3918">
                            <rect width="10" height="10" fill="white" transform="translate(0 0.5)" />
                        </clipPath>
                    </defs>
                </svg>

            </div>
            <div className="border" ></div>

            <div onClick={()=>onChangeCandidateFilter('All')} style={candidateFilter==="All"?{ cursor: 'pointer',backgroundColor:'#384455' }:{cursor:'pointer'}} className="status-content-container" >
                <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.0026 7.43717C5.54427 7.43717 6.0026 7.24967 6.3776 6.87467C6.7526 6.49967 6.9401 6.04134 6.9401 5.49967C6.9401 4.95801 6.7526 4.49967 6.3776 4.12467C6.0026 3.74967 5.54427 3.56217 5.0026 3.56217C4.46094 3.56217 4.0026 3.74967 3.6276 4.12467C3.2526 4.49967 3.0651 4.95801 3.0651 5.49967C3.0651 6.04134 3.2526 6.49967 3.6276 6.87467C4.0026 7.24967 4.46094 7.43717 5.0026 7.43717ZM5.0026 9.66634C4.43316 9.66634 3.89497 9.55697 3.38802 9.33822C2.88108 9.11947 2.43837 8.82085 2.0599 8.44238C1.68142 8.06391 1.38281 7.6212 1.16406 7.11426C0.945312 6.60731 0.835938 6.06912 0.835938 5.49967C0.835938 4.92329 0.945312 4.38162 1.16406 3.87467C1.38281 3.36773 1.68142 2.92676 2.0599 2.55176C2.43837 2.17676 2.88108 1.87988 3.38802 1.66113C3.89497 1.44238 4.43316 1.33301 5.0026 1.33301C5.57899 1.33301 6.12066 1.44238 6.6276 1.66113C7.13455 1.87988 7.57552 2.17676 7.95052 2.55176C8.32552 2.92676 8.6224 3.36773 8.84115 3.87467C9.0599 4.38162 9.16927 4.92329 9.16927 5.49967C9.16927 6.06912 9.0599 6.60731 8.84115 7.11426C8.6224 7.6212 8.32552 8.06391 7.95052 8.44238C7.57552 8.82085 7.13455 9.11947 6.6276 9.33822C6.12066 9.55697 5.57899 9.66634 5.0026 9.66634ZM5.0026 9.04134C5.98871 9.04134 6.82552 8.69585 7.51302 8.00488C8.20052 7.31391 8.54427 6.47884 8.54427 5.49967C8.54427 4.51356 8.20052 3.67676 7.51302 2.98926C6.82552 2.30176 5.98871 1.95801 5.0026 1.95801C4.02344 1.95801 3.18837 2.30176 2.4974 2.98926C1.80642 3.67676 1.46094 4.51356 1.46094 5.49967C1.46094 6.47884 1.80642 7.31391 2.4974 8.00488C3.18837 8.69585 4.02344 9.04134 5.0026 9.04134Z" fill={candidateFilter==="All"?"white" :"black"} />
                </svg>
                <span style={candidateFilter === "All"?{color:'white'}:{color:'black'}} >All</span>
                {candidateFilter==="All"?
                <svg className="selected-icon" width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 6.1L3.4 8.5L9.4 2.5" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>:<></>
                
                }
            </div>

            <div onClick={()=>onChangeCandidateFilter('Passed')} style={candidateFilter==="Passed"?{ cursor: 'pointer',backgroundColor:'#384455' }:{cursor:'pointer'}} className="status-content-container" >
                <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.0026 7.43717C5.54427 7.43717 6.0026 7.24967 6.3776 6.87467C6.7526 6.49967 6.9401 6.04134 6.9401 5.49967C6.9401 4.95801 6.7526 4.49967 6.3776 4.12467C6.0026 3.74967 5.54427 3.56217 5.0026 3.56217C4.46094 3.56217 4.0026 3.74967 3.6276 4.12467C3.2526 4.49967 3.0651 4.95801 3.0651 5.49967C3.0651 6.04134 3.2526 6.49967 3.6276 6.87467C4.0026 7.24967 4.46094 7.43717 5.0026 7.43717ZM5.0026 9.66634C4.43316 9.66634 3.89497 9.55697 3.38802 9.33822C2.88108 9.11947 2.43837 8.82085 2.0599 8.44238C1.68142 8.06391 1.38281 7.6212 1.16406 7.11426C0.945312 6.60731 0.835938 6.06912 0.835938 5.49967C0.835938 4.92329 0.945312 4.38162 1.16406 3.87467C1.38281 3.36773 1.68142 2.92676 2.0599 2.55176C2.43837 2.17676 2.88108 1.87988 3.38802 1.66113C3.89497 1.44238 4.43316 1.33301 5.0026 1.33301C5.57899 1.33301 6.12066 1.44238 6.6276 1.66113C7.13455 1.87988 7.57552 2.17676 7.95052 2.55176C8.32552 2.92676 8.6224 3.36773 8.84115 3.87467C9.0599 4.38162 9.16927 4.92329 9.16927 5.49967C9.16927 6.06912 9.0599 6.60731 8.84115 7.11426C8.6224 7.6212 8.32552 8.06391 7.95052 8.44238C7.57552 8.82085 7.13455 9.11947 6.6276 9.33822C6.12066 9.55697 5.57899 9.66634 5.0026 9.66634ZM5.0026 9.04134C5.98871 9.04134 6.82552 8.69585 7.51302 8.00488C8.20052 7.31391 8.54427 6.47884 8.54427 5.49967C8.54427 4.51356 8.20052 3.67676 7.51302 2.98926C6.82552 2.30176 5.98871 1.95801 5.0026 1.95801C4.02344 1.95801 3.18837 2.30176 2.4974 2.98926C1.80642 3.67676 1.46094 4.51356 1.46094 5.49967C1.46094 6.47884 1.80642 7.31391 2.4974 8.00488C3.18837 8.69585 4.02344 9.04134 5.0026 9.04134Z" fill={candidateFilter==="Passed"?"white" :"black"} />
                </svg>
                <span style={candidateFilter === "Passed"?{color:'white'}:{color:'black'}} >Passed</span>
                {candidateFilter==="Passed"?
                <svg className="selected-icon" width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 6.1L3.4 8.5L9.4 2.5" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>:<></>
                
                }
            </div>
            
            <div onClick={()=>onChangeCandidateFilter('Failed')} style={candidateFilter==="Failed"?{ cursor: 'pointer',backgroundColor:'#384455' }:{cursor:'pointer'}} className="status-content-container" >
                <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.0026 7.43717C5.54427 7.43717 6.0026 7.24967 6.3776 6.87467C6.7526 6.49967 6.9401 6.04134 6.9401 5.49967C6.9401 4.95801 6.7526 4.49967 6.3776 4.12467C6.0026 3.74967 5.54427 3.56217 5.0026 3.56217C4.46094 3.56217 4.0026 3.74967 3.6276 4.12467C3.2526 4.49967 3.0651 4.95801 3.0651 5.49967C3.0651 6.04134 3.2526 6.49967 3.6276 6.87467C4.0026 7.24967 4.46094 7.43717 5.0026 7.43717ZM5.0026 9.66634C4.43316 9.66634 3.89497 9.55697 3.38802 9.33822C2.88108 9.11947 2.43837 8.82085 2.0599 8.44238C1.68142 8.06391 1.38281 7.6212 1.16406 7.11426C0.945312 6.60731 0.835938 6.06912 0.835938 5.49967C0.835938 4.92329 0.945312 4.38162 1.16406 3.87467C1.38281 3.36773 1.68142 2.92676 2.0599 2.55176C2.43837 2.17676 2.88108 1.87988 3.38802 1.66113C3.89497 1.44238 4.43316 1.33301 5.0026 1.33301C5.57899 1.33301 6.12066 1.44238 6.6276 1.66113C7.13455 1.87988 7.57552 2.17676 7.95052 2.55176C8.32552 2.92676 8.6224 3.36773 8.84115 3.87467C9.0599 4.38162 9.16927 4.92329 9.16927 5.49967C9.16927 6.06912 9.0599 6.60731 8.84115 7.11426C8.6224 7.6212 8.32552 8.06391 7.95052 8.44238C7.57552 8.82085 7.13455 9.11947 6.6276 9.33822C6.12066 9.55697 5.57899 9.66634 5.0026 9.66634ZM5.0026 9.04134C5.98871 9.04134 6.82552 8.69585 7.51302 8.00488C8.20052 7.31391 8.54427 6.47884 8.54427 5.49967C8.54427 4.51356 8.20052 3.67676 7.51302 2.98926C6.82552 2.30176 5.98871 1.95801 5.0026 1.95801C4.02344 1.95801 3.18837 2.30176 2.4974 2.98926C1.80642 3.67676 1.46094 4.51356 1.46094 5.49967C1.46094 6.47884 1.80642 7.31391 2.4974 8.00488C3.18837 8.69585 4.02344 9.04134 5.0026 9.04134Z" fill={candidateFilter==="Failed"?"white" :"black"} />
                </svg>
                <span style={candidateFilter === "Failed"?{color:'white'}:{color:'black'}} >Failed</span>
                {candidateFilter==="Failed"?
                <svg className="selected-icon" width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 6.1L3.4 8.5L9.4 2.5" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>:<></>
                
                }
            </div>


            <div onClick={()=>onChangeCandidateFilter('Shortlisted')} style={candidateFilter==="Shortlisted"?{ cursor: 'pointer',backgroundColor:'#384455' }:{cursor:'pointer'}} className="status-content-container" >
                <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.0026 7.43717C5.54427 7.43717 6.0026 7.24967 6.3776 6.87467C6.7526 6.49967 6.9401 6.04134 6.9401 5.49967C6.9401 4.95801 6.7526 4.49967 6.3776 4.12467C6.0026 3.74967 5.54427 3.56217 5.0026 3.56217C4.46094 3.56217 4.0026 3.74967 3.6276 4.12467C3.2526 4.49967 3.0651 4.95801 3.0651 5.49967C3.0651 6.04134 3.2526 6.49967 3.6276 6.87467C4.0026 7.24967 4.46094 7.43717 5.0026 7.43717ZM5.0026 9.66634C4.43316 9.66634 3.89497 9.55697 3.38802 9.33822C2.88108 9.11947 2.43837 8.82085 2.0599 8.44238C1.68142 8.06391 1.38281 7.6212 1.16406 7.11426C0.945312 6.60731 0.835938 6.06912 0.835938 5.49967C0.835938 4.92329 0.945312 4.38162 1.16406 3.87467C1.38281 3.36773 1.68142 2.92676 2.0599 2.55176C2.43837 2.17676 2.88108 1.87988 3.38802 1.66113C3.89497 1.44238 4.43316 1.33301 5.0026 1.33301C5.57899 1.33301 6.12066 1.44238 6.6276 1.66113C7.13455 1.87988 7.57552 2.17676 7.95052 2.55176C8.32552 2.92676 8.6224 3.36773 8.84115 3.87467C9.0599 4.38162 9.16927 4.92329 9.16927 5.49967C9.16927 6.06912 9.0599 6.60731 8.84115 7.11426C8.6224 7.6212 8.32552 8.06391 7.95052 8.44238C7.57552 8.82085 7.13455 9.11947 6.6276 9.33822C6.12066 9.55697 5.57899 9.66634 5.0026 9.66634ZM5.0026 9.04134C5.98871 9.04134 6.82552 8.69585 7.51302 8.00488C8.20052 7.31391 8.54427 6.47884 8.54427 5.49967C8.54427 4.51356 8.20052 3.67676 7.51302 2.98926C6.82552 2.30176 5.98871 1.95801 5.0026 1.95801C4.02344 1.95801 3.18837 2.30176 2.4974 2.98926C1.80642 3.67676 1.46094 4.51356 1.46094 5.49967C1.46094 6.47884 1.80642 7.31391 2.4974 8.00488C3.18837 8.69585 4.02344 9.04134 5.0026 9.04134Z" fill={candidateFilter==="Shortlisted"?"white" :"black"} />
                </svg>
                <span style={candidateFilter === "Shortlisted"?{color:'white'}:{color:'black'}} >Shortlisted</span>
                {candidateFilter==="Shortlisted"?
                <svg className="selected-icon" width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 6.1L3.4 8.5L9.4 2.5" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>:<></>
                
                }
            </div>

            <div onClick={()=>onChangeCandidateFilter('Reviewed')} style={candidateFilter==="Reviewed"?{ cursor: 'pointer',backgroundColor:'#384455' }:{cursor:'pointer'}}  className="status-content-container" >
                <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.0026 7.43717C5.54427 7.43717 6.0026 7.24967 6.3776 6.87467C6.7526 6.49967 6.9401 6.04134 6.9401 5.49967C6.9401 4.95801 6.7526 4.49967 6.3776 4.12467C6.0026 3.74967 5.54427 3.56217 5.0026 3.56217C4.46094 3.56217 4.0026 3.74967 3.6276 4.12467C3.2526 4.49967 3.0651 4.95801 3.0651 5.49967C3.0651 6.04134 3.2526 6.49967 3.6276 6.87467C4.0026 7.24967 4.46094 7.43717 5.0026 7.43717ZM5.0026 9.66634C4.43316 9.66634 3.89497 9.55697 3.38802 9.33822C2.88108 9.11947 2.43837 8.82085 2.0599 8.44238C1.68142 8.06391 1.38281 7.6212 1.16406 7.11426C0.945312 6.60731 0.835938 6.06912 0.835938 5.49967C0.835938 4.92329 0.945312 4.38162 1.16406 3.87467C1.38281 3.36773 1.68142 2.92676 2.0599 2.55176C2.43837 2.17676 2.88108 1.87988 3.38802 1.66113C3.89497 1.44238 4.43316 1.33301 5.0026 1.33301C5.57899 1.33301 6.12066 1.44238 6.6276 1.66113C7.13455 1.87988 7.57552 2.17676 7.95052 2.55176C8.32552 2.92676 8.6224 3.36773 8.84115 3.87467C9.0599 4.38162 9.16927 4.92329 9.16927 5.49967C9.16927 6.06912 9.0599 6.60731 8.84115 7.11426C8.6224 7.6212 8.32552 8.06391 7.95052 8.44238C7.57552 8.82085 7.13455 9.11947 6.6276 9.33822C6.12066 9.55697 5.57899 9.66634 5.0026 9.66634ZM5.0026 9.04134C5.98871 9.04134 6.82552 8.69585 7.51302 8.00488C8.20052 7.31391 8.54427 6.47884 8.54427 5.49967C8.54427 4.51356 8.20052 3.67676 7.51302 2.98926C6.82552 2.30176 5.98871 1.95801 5.0026 1.95801C4.02344 1.95801 3.18837 2.30176 2.4974 2.98926C1.80642 3.67676 1.46094 4.51356 1.46094 5.49967C1.46094 6.47884 1.80642 7.31391 2.4974 8.00488C3.18837 8.69585 4.02344 9.04134 5.0026 9.04134Z" fill={candidateFilter==="Reviewed"?"white" :"black"} />
                </svg>
                <span style={candidateFilter === "Reviewed"?{color:'white'}:{color:'black'}} >Reviewed</span>
                {candidateFilter==="Reviewed"?
                <svg className="selected-icon" width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 6.1L3.4 8.5L9.4 2.5" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>:<></>
                
                }
            </div>

            {/* <div onClick={()=>onChangeCandidateFilter('Review Pending')} style={candidateFilter==="Review Pending"?{ cursor: 'pointer',backgroundColor:'#384455' }:{cursor:'pointer'}}  className="status-content-container" >
                
                <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.0026 7.43717C5.54427 7.43717 6.0026 7.24967 6.3776 6.87467C6.7526 6.49967 6.9401 6.04134 6.9401 5.49967C6.9401 4.95801 6.7526 4.49967 6.3776 4.12467C6.0026 3.74967 5.54427 3.56217 5.0026 3.56217C4.46094 3.56217 4.0026 3.74967 3.6276 4.12467C3.2526 4.49967 3.0651 4.95801 3.0651 5.49967C3.0651 6.04134 3.2526 6.49967 3.6276 6.87467C4.0026 7.24967 4.46094 7.43717 5.0026 7.43717ZM5.0026 9.66634C4.43316 9.66634 3.89497 9.55697 3.38802 9.33822C2.88108 9.11947 2.43837 8.82085 2.0599 8.44238C1.68142 8.06391 1.38281 7.6212 1.16406 7.11426C0.945312 6.60731 0.835938 6.06912 0.835938 5.49967C0.835938 4.92329 0.945312 4.38162 1.16406 3.87467C1.38281 3.36773 1.68142 2.92676 2.0599 2.55176C2.43837 2.17676 2.88108 1.87988 3.38802 1.66113C3.89497 1.44238 4.43316 1.33301 5.0026 1.33301C5.57899 1.33301 6.12066 1.44238 6.6276 1.66113C7.13455 1.87988 7.57552 2.17676 7.95052 2.55176C8.32552 2.92676 8.6224 3.36773 8.84115 3.87467C9.0599 4.38162 9.16927 4.92329 9.16927 5.49967C9.16927 6.06912 9.0599 6.60731 8.84115 7.11426C8.6224 7.6212 8.32552 8.06391 7.95052 8.44238C7.57552 8.82085 7.13455 9.11947 6.6276 9.33822C6.12066 9.55697 5.57899 9.66634 5.0026 9.66634ZM5.0026 9.04134C5.98871 9.04134 6.82552 8.69585 7.51302 8.00488C8.20052 7.31391 8.54427 6.47884 8.54427 5.49967C8.54427 4.51356 8.20052 3.67676 7.51302 2.98926C6.82552 2.30176 5.98871 1.95801 5.0026 1.95801C4.02344 1.95801 3.18837 2.30176 2.4974 2.98926C1.80642 3.67676 1.46094 4.51356 1.46094 5.49967C1.46094 6.47884 1.80642 7.31391 2.4974 8.00488C3.18837 8.69585 4.02344 9.04134 5.0026 9.04134Z" fill={candidateFilter==="Review Pending"?"white" :"black"} />
                </svg>
                <span style={candidateFilter === "Review Pending"?{color:'white'}:{color:'black'}} >Review Pending</span>
                {candidateFilter==="Review Pending"?
                <svg className="selected-icon" width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 6.1L3.4 8.5L9.4 2.5" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>:<></>
                
                }
               

            </div> */}
        </div>
    )
}

export default CandidateStatusFilter