import { Grid, TextField, InputLabel } from "@mui/material";
import React, { useState } from "react";
import eastIcon from "../../assets/icon/easy-icon.svg";
import hardIcon from "../../assets/icon/hard-icon.svg";
import mediumIcon from "../../assets/icon/medium-icon.svg";

import "./index.css";
import Warning from "./Warning";
function SkillSetTable(props) {
  const { queType, easy, medium, hard } = props;

  const [easyMarks, setEasyMarks] = useState(0);
  const [mediumMarks, setMediumMarks] = useState(0);
  const [hardMarks, setHardMarks] = useState(0);

  const [easyMarksError, seteasyMarksError] = useState(false);
  const [mediumMarksError, setmediumMarksError] = useState(false);
  const [hardMarksError, sethardMarksError] = useState(false);

  const onChangeEasyQuestionCount = (e) => {
    const newValue = e.target.value.slice(0, 3);
    setEasyMarks(newValue);
    if (
      newValue === "" ||
      parseInt(newValue) < 0 ||
      /^0+$|^0*-0+$/.test(newValue) ||
      parseInt(newValue) > hard
    ) {
      seteasyMarksError(true);
    } else {
      seteasyMarksError(false);
    }
  };

  const onChangeMediumQuestionCount = (e) => {
    const newValue = e.target.value.slice(0, 3);
    setMediumMarks(newValue);
    if (
      newValue === "" ||
      parseInt(newValue) < 0 ||
      /^0+$|^0*-0+$/.test(newValue) ||
      parseInt(newValue) > hard
    ) {
      setmediumMarksError(true);
    } else {
      setmediumMarksError(false);
    }
  };

  const onChangeHardQuestionCount = (e) => {
    const newValue = e.target.value.slice(0, 3);
    setHardMarks(newValue);
    if (
      newValue === "" ||
      parseInt(newValue) < 0 ||
      /^0+$|^0*-0+$/.test(newValue) ||
      parseInt(newValue) > hard
    ) {
      sethardMarksError(true);
    } else {
      sethardMarksError(false);
    }
  };

  const handleSubmitFunction = (e) => {
    e.preventDefault();
    console.log("easy : ", easyMarks);
  };

  return (
    <>
      <Grid xs={12} md={8} mt={2}>
        <div className="skill-table">
          <table className="skillset" cellSpacing="0px">
            <thead>
              <tr>
                <th>Difficulty</th>
                <th>Fixed Questions</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ height: "42px" }}>
                  <img style={{ cursor: "pointer" }} src={eastIcon} alt="" />
                  Easy ({easy})
                </td>
                <td style={{ height: "42px" }}>
                  <TextField
                    error={easyMarks > easy ? true : false}
                    id={`textField-1`}
                    size="small"
                    style={{
                      width: "40px",
                    }}
                    InputProps={{
                      inputProps: {
                        type: "number",
                        pattern: "[0-9]*",
                      },
                    }}
                    onWheel={(e) => e.target.blur()}
                    onKeyDown={(evt) =>
                      (evt.key === "e" ||
                        evt.keyCode === 190 ||
                        evt.keyCode === 110) &&
                      evt.preventDefault()
                    }
                    min={0}
                    max={100}
                    value={easyMarks}
                    defaultValue={easyMarks ? easyMarks : 0}
                    onChange={onChangeEasyQuestionCount}
                    type="number"
                  />
                </td>
              </tr>
              <tr>
                <td style={{ height: "42px" }}>
                  <img
                    onClick={props.closeSkillSetModel}
                    style={{ cursor: "pointer" }}
                    src={mediumIcon}
                    alt=""
                  />
                  Medium ({medium})
                </td>
                <td style={{ height: "42px" }}>
                  <TextField
                    error={mediumMarks > medium ? true : false}
                    id={`textField-1`}
                    size="small"
                    style={{ width: "40px" }}
                    InputProps={{
                      inputProps: {
                        type: "number",
                        pattern: "[0-9]*",
                      },
                    }}
                    onWheel={(e) => e.target.blur()}
                    onKeyDown={(evt) =>
                      (evt.key === "e" ||
                        evt.keyCode === 190 ||
                        evt.keyCode === 110) &&
                      evt.preventDefault()
                    }
                    min={0}
                    max={100}
                    value={mediumMarks}
                    defaultValue={mediumMarks ? mediumMarks : 0}
                    onChange={onChangeMediumQuestionCount}
                    type="number"
                  />
                </td>
              </tr>
              <tr>
                <td style={{ height: "42px" }}>
                  <img
                    onClick={props.closeSkillSetModel}
                    style={{ cursor: "pointer" }}
                    src={hardIcon}
                    alt=""
                  />
                  Hard ({hard})
                </td>
                <td style={{ height: "42px" }}>
                  <TextField
                    error={hardMarks > hard ? true : false}
                    id={`textField-1`}
                    size="small"
                    style={{ width: "40px" }}
                    InputProps={{
                      inputProps: {
                        type: "number",
                        pattern: "[0-9]*",
                      },
                    }}
                    maxLength={3}
                    value={hardMarks}
                    defaultValue={hardMarks ? hardMarks : 0}
                    onChange={onChangeHardQuestionCount}
                    onWheel={(e) => e.target.blur()}
                    onKeyDown={(evt) =>
                      (evt.key === "e" ||
                        evt.keyCode === 190 ||
                        evt.keyCode === 110) &&
                      evt.preventDefault()
                    }
                    min={0}
                    max={100}
                    type="number"
                  />
                </td>
              </tr>
              <tr>
                <th>Total Questions ({easy + medium + hard})</th>
                <th>
                  {parseInt(easyMarks ? easyMarks : 0) +
                    parseInt(mediumMarks ? mediumMarks : 0) +
                    parseInt(hardMarks ? hardMarks : 0)}
                </th>
              </tr>
            </tbody>
          </table>
        </div>
      </Grid>
      {/* {easy + medium + hard <
        parseInt(easyMarks ? easyMarks : 0) +
          parseInt(mediumMarks ? mediumMarks : 0) +
          parseInt(hardMarks ? hardMarks : 0) && (
        <Warning
          msg={
            "Random questions should be less than no. of available questions"
          }
        />
      )} */}
      {hard < parseInt(hardMarks ? hardMarks : 0) && (
        <Warning msg={`No. of hard questions should be <= ${hard}`} />
      )}
      {0 > parseInt(hardMarks ? hardMarks : 0) && (
        <Warning msg={`Hard questions should be greater than zero`} />
      )}
      {medium < parseInt(mediumMarks ? mediumMarks : 0) && (
        <Warning msg={`No. of medium questions should be <= ${medium}`} />
      )}
      {0 > parseInt(mediumMarks ? mediumMarks : 0) && (
        <Warning msg={"Medium questions should be greater than zero"} />
      )}
      {easy < parseInt(easyMarks ? easyMarks : 0) && (
        <Warning msg={`No. of easy questions should be <= ${easy}`} />
      )}
      {0 > parseInt(easyMarks ? easyMarks : 0) && (
        <Warning msg={"Easy questions should be greater than zero"} />
      )}
    </>
  );
}

export default SkillSetTable;
